$(document).ready(function () {

    if($(".calendrier-avent2023").length) {
        //*--------------------------------------------//
        //* Snow                                       //
        //* https://codepen.io/BlueBottle/pen/RwJJaRR  //
        //*--------------------------------------------//
        function createSnowFlake() {
            const snow_flake = document.createElement('i');
            snow_flake.classList.add('fas', 'fa-snowflake', 'snowflake');

            const x_shift = Math.random() * window.innerWidth;
            snow_flake.style.left = `${x_shift}px`;

            const duration = Math.random() * 3 + 2;
            snow_flake.style.animationDuration = `${duration}s`;

            snow_flake.style.opacity = Math.random();

            const size = Math.random() * 10;
            snow_flake.style.fontSize = `${size}px`;

            snow_flake.style.filter = "blur(1px)"

            document.body.appendChild(snow_flake);

            snow_flake.onanimationend = () => snow_flake.remove();

            requestAnimationFrame(createSnowFlake);
        }
        requestAnimationFrame(createSnowFlake);

        //-------------------------------------------//
        // End Snow                                  //
        //-------------------------------------------//
    }



});