$(document).ready(function() {
  String.prototype.slugify = function (separator = "-") {
    return this
      .toString()
      .normalize('NFD')                 // split an accented letter in the base letter and the acent
      .replace(/[\u0300-\u036f]/g, '')  // remove all previously split accents
      .toLowerCase()
      .trim()
      .replace(/[^a-z0-9 ]/g, '')       // remove all chars not letters, numbers and spaces (to be replaced)
      .replace(/\s+/g, separator);
  };

	if ($('#splide-carousel_publicite').length > 0) {
        new Splide('#splide-carousel_publicite', {
            type: 'loop',
            perPage: 1,
            autoplay: true,
            arrows: true,
            interval: 7500,
            pagination: false,
        }).mount();
    }

      
  // Lors du click sur un (i) on affiche les mentions légales
  $('body').on('click', 'i[id^="cgi-ml-"]', function() {
    $.ajax({
        type: 'POST',
        url: siteURL + "financement/mentions-legales/" + this.id.replace('cgi-ml-', ''),
        success: function(data) {
          //Affiche le contenu de la popup dans une fancybox
          fancybox_cgi = new Fancybox([{
            src: '<div class="p-3">'+data+'</div>',
            type: "html",
          }]);
        }
    });
    return false;
  });

  // if (window.innerWidth < 575.98) {

  //   $(".animsition").animsition({
  //     // inClass: 'fade-in-left',
  //     // outClass: 'fade-out-left',
  //     inDuration: 1500,
  //     outDuration: 800,
  //     linkElement: '.animsition-link',
  //     // e.g. linkElement: 'a:not([target="_blank"]):not([href^="#"])'
  //     loading: true,
  //     loadingParentElement: 'body', //animsition wrapper element
  //     loadingClass: 'animsition-loading',
  //     loadingInner: '', // e.g '<img src="loading.svg" />'
  //     timeout: false,
  //     timeoutCountdown: 5000,
  //     onLoadEvent: true,
  //     browser: [ 'animation-duration', '-webkit-animation-duration'],
  //     // "browser" option allows you to disable the "animsition" in case the css property in the array is not supported by your browser.
  //     // The default setting is to disable the "animsition" in a browser that does not support "animation-duration".
  //     overlay : false,
  //     overlayClass : 'animsition-overlay-slide',
  //     overlayParentElement : 'body',
  //     transition: function(url){ window.location.href = url; }
  //   });
  // }
});
