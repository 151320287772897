    $('#display_comparateur').on('click', function() {
        if (typeof comparateur_shown === "undefined" || comparateur_shown == 0) {
            $('#show_comparateur').animate({bottom: 0});
            comparateur_shown = 1;
        }
        else {
            $('#show_comparateur').animate({bottom: - $('.contenu_comparateur').height() +1});
            comparateur_shown = 0;
        }
        $('#comparateur_chevron').toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
    });

    $('body').on('click', '#comparateur_empty', function() {
        $('#show_comparateur').animate({bottom: -136}, function() {
            $('#show_comparateur').addClass('d-none');
            $('[id^="comparateur_vehicule_photo"]').each( function() { $(this).remove(); })
            $('[id^="comparateur_vehicule_text"]').each( function() { $(this).remove(); })
            comparateur_shown = 0;
            $('.fa-check-square').each( function() {
                $(this).toggleClass('fa-check-square').toggleClass('fa-square');
            })
            document.cookie = 'ora7_compare=""; path=/;expires='+(new Date(Date.now())).toUTCString();
        }); 
    });

    $('body').on('click', '[id^="comparateur_vehiculedelete_"]', function() {
        var id = this.id.replace("comparateur_vehiculedelete_", "");
        
        if ($('[id^="comparateur_vehicule_photo"]').length == 1) {
            $('#show_comparateur').animate({bottom: -136}, function() {
                $('#show_comparateur').addClass('d-none');
                $('#comparateur_vehicule_photo'+id).remove();
            $('#comparateur_vehicule_text'+id).remove();
                comparateur_shown = 0;
            }); 
        }
        else {
            $('#comparateur_vehicule_photo'+id).remove();
            $('#comparateur_vehicule_text'+id).remove();
        }
        $('#comparateur_nombreheader').text('('+$('[id^="comparateur_vehicule_photo"]').length+')');
        $('#comparateur_nombre').text($('[id^="comparateur_vehicule_photo"]').length + " véhicule"+ ($('[id^="comparateur_vehicule_photo"]').length >1 ? "s" : ""));
        var cookieactuel = document.cookie.replace(/(?:(?:^|.*;\s*)ora7_compare\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        cookieactuel = cookieactuel.split(',')
        index = cookieactuel.indexOf(id);
        if (index > -1) {
          cookieactuel.splice(index, 1);
        }
        cookieactuel = cookieactuel.join(',');
        // if ($('#results_compare_add_'+id).length > 0) {$('#results_compare_add_'+id).html('Comparer&nbsp;&nbsp;<i class="far fa-square"></i>'); }
        // if ($('#compare_add_'+id).length > 0) {$('#compare_add_'+id).html('<i class="fal fa-exchange-alt fa-rotate-90"></i>&nbsp;Comparer&nbsp;&nbsp;<i class="far fa-square"></i>'); }
        document.cookie = 'ora7_compare='+cookieactuel+'; path=/;max-age=86400';
    });

    $('body').on('click', '[id^="compare_add_"]', function() {
        var id = this.id.replace("compare_add_", "");
        if ($('[id^="comparateur_vehicule_photo"]').length < 3 && $('#comparateur_vehicule_photo'+id).length == 0) {
            infos = Array();
            infos.marquemodele = $('.annonce-marquemodele').text();
            infos.id=id;
            infos.finition = $('.annonce-version').text();
            infos.price = $('.prix-valeur-ttc').text();
            infos.loyer = $('.annonce-prix-credit').text();
            console.log(infos.loyer);
            new_compare = generateComparateurCard(infos);
            $('#comparateur_after').before(new_compare);
            if (typeof comparateur_shown === "undefined" || comparateur_shown == 0) {
                $('#show_comparateur').removeClass('d-none');
                $('#show_comparateur').animate({bottom: 0});
                $('#comparateur_chevron').removeClass('fa-chevron-up').removeClass('fa-chevron-down');
                $('#comparateur_chevron').addClass('fa-chevron-down');
                comparateur_shown = 1;
            } else {
                comparateur_shown = 1;
                $('#comparateur_chevron').removeClass('fa-chevron-up').removeClass('fa-chevron-down');
                $('#comparateur_chevron').addClass('fa-chevron-down');
            }
            $('#comparateur_nombreheader').text('('+$('[id^="comparateur_vehicule_photo"]').length+')');
            $('#comparateur_nombre').text($('[id^="comparateur_vehicule_photo"]').length + " véhicule"+ ($('[id^="comparateur_vehicule_photo"]').length >1 ? "s" : ""));
            var cookieactuel = document.cookie.replace(/(?:(?:^|.*;\s*)ora7_compare\s*\=\s*([^;]*).*$)|^.*$/, "$1");
            document.cookie = 'ora7_compare='+id+','+cookieactuel+'; path=/;max-age=86400';
            // $(this).html('<i class="fal fa-exchange-alt fa-rotate-90"></i>&nbsp;Comparer&nbsp;&nbsp;<i class="far fa-check-square"></i>');
        } else if ($('[id^="comparateur_vehicule_photo"]').length = 3 && $('#comparateur_vehicule_photo'+id).length == 0) {
            // Si ajout de plus de 3 voitures afficher modal
            $('#modalComparateur').modal('show');
        }
        return false;
    });

    $('body').on('click', '[id^="results_compare_add_"]', function() {
        var id = this.id.replace("results_compare_add_", "");
        if ($('[id^="comparateur_vehicule_photo"]').length < 3 && $('#comparateur_vehicule_photo'+id).length == 0) {
            infos = Array();
            infos.id=id;
            infos.marquemodele = $(this).parent().parent().parent().parent().parent().parent().parent().find('.item-info').find('.item-title').find('.title-vehicule').text().replace(/(\r\n|\n|\r)/gm, "");
            infos.finition = $(this).parent().parent().parent().parent().parent().parent().parent().find('.item-info').find('.item-title').find('.subtitle-vehicule').text().replace(/(\r\n|\n|\r)/gm, "");
            infos.price = $(this).parent().parent().parent().parent().parent().parent().parent().find('.item-content').find('.price-ttc').text().replace(/(\r\n|\n|\r)/gm, "");
            infos.loyer = $(this).parent().parent().parent().parent().parent().parent().parent().find('.item-content').find('.prix-valeur').text().replace(/(\r\n|\n|\r)/gm, "") + " €";
            new_compare = generateComparateurCard(infos);
            $('#comparateur_after').before(new_compare);
            if (typeof comparateur_shown === "undefined" || comparateur_shown == 0) {
                $('#show_comparateur').removeClass('d-none');
                $('#show_comparateur').animate({bottom: 0});
                $('#comparateur_chevron').removeClass('fa-chevron-up').removeClass('fa-chevron-down');
                $('#comparateur_chevron').addClass('fa-chevron-down');
                comparateur_shown = 1;
            } else {
                comparateur_shown = 1;
                $('#comparateur_chevron').removeClass('fa-chevron-up').removeClass('fa-chevron-down');
                $('#comparateur_chevron').addClass('fa-chevron-down');
            }
            $('#comparateur_nombreheader').text('('+$('[id^="comparateur_vehicule_photo"]').length+')');
            $('#comparateur_nombre').text($('[id^="comparateur_vehicule_photo"]').length + " véhicule"+ ($('[id^="comparateur_vehicule_photo"]').length >1 ? "s" : ""));
            var cookieactuel = document.cookie.replace(/(?:(?:^|.*;\s*)ora7_compare\s*\=\s*([^;]*).*$)|^.*$/, "$1");
            document.cookie = 'ora7_compare='+id+','+cookieactuel+'; path=/;max-age=86400';
            // $(this).html('Comparer&nbsp;&nbsp;<i class="far fa-check-square"></i>');
        } else if ($('[id^="comparateur_vehicule_photo"]').length = 3 && $('#comparateur_vehicule_photo'+id).length == 0) {
            // Si ajout de plus de 3 voitures afficher modal
            $('#modalComparateur').modal('show');
        }
        return false;
    });

	function generateComparateurCard(infos) {
	    output = '<div class="col-12 col-lg-4 mt-3 my-auto" id="comparateur_vehicule_photo'+infos.id+'">';
        output += '<img class="img-fluid w-100" src="'+siteURL+'cardiff/photos/thumbs/'+infos.id+'_1.jpg">';
        output += '</div></div>';
        output += '<div class="col-12 col-lg-4 mt-3 my-auto text-start" id="comparateur_vehicule_text'+infos.id+'">';
        output += '<div class="position-absolute col-11 col-lg-2">';
        output += '<span id="comparateur_vehiculedelete_'+infos.id+'" class="delete_comparateur position-absolute end-0 cursor-pointer"><i class="fal fa-times"></i></span>';
        output += '<div class="font-josefin"style="font-size:12px;"><strong>'+infos.marquemodele+'</strong></div>';
        output += '<div class="font-josefin finition_comparateur" style="font-size:12px;">'+infos.finition+'</div>';
        output += '<div class="price_comparateur fw-bold">'+infos.price+' TTC</div>';
          if (infos.loyer != "") {
            output += '<div class="loyer_comparateur d-inline-block">dès <span class="fw-bold">'+infos.loyer+'</span>/mois</div>';
          }
        output += '</div>';

	    return output;
	}