$(document).ready(function() {
$('[id^=aventcase-').on('click', function() {
    var jourDemande = this.id.replace('aventcase-', '');
    var jourActuel = (new Date()).getDate();
    // Ne fonctionne que pour le mois de décembre
    if ((new Date()).getMonth() == 11) {
        if (jourDemande <= jourActuel) {
            if (!$(this).hasClass('aventcaseOpen')) {
                $(this).addClass('aventcaseOpen');
            }
            else {
                $(this).removeClass('aventcaseOpen');
            }
        }
    }
})

});