$(window).on('scroll', function() {
    scrollPosition = $(this).scrollTop();
    var width = $(window).width();

    // FILTRE MOTEUR DE RECHERCHE MOBILE
    if ($('#filtres_mobiles').length > 0 && width < 991.98) {
        if (width < 991.98 && width > 575.98) {
            if (scrollPosition >= 75) {
                if ($("nav").css('padding-bottom') == "160px") {
                    $("#header-barre-haute").slideUp();
                    $("#header-container").addClass("fixed-header");
                }
            }
            else {
                $("#header-barre-haute").slideDown();
                $("#header-container").addClass("fixed-header");        
            }
            if (scrollPosition >= 100 && scrollPosition <= 75) {
                $('nav').css("padding-bottom", "0");
            }
            else {
                $('nav').css("padding-bottom", "160px");
            }
        } else if (width < 575.98) {
            if (scrollPosition >= 75) {
                if ($("nav").css('padding-bottom') == "120px") {
                    $("#header-barre-basse").slideUp();
                    $("#header-container").addClass("fixed-header");
                }
            } else {
                $("#header-barre-basse").slideDown();
                $("#header-container").addClass("fixed-header"); 
            }
            if (scrollPosition >= 100 && scrollPosition <= 75) {
                $('nav').css("padding-bottom", "0");
            }
            else {
                $('nav').css("padding-bottom", "120px");
            }
            // $("#header-barre-basse").slideUp();
            // $("#header-container").addClass("fixed-header");
            // $('nav').css("padding-bottom", "60px");
        }
    } else {
        // Version "normal"
        if (width > 575.98) {
            if (scrollPosition >= 75) {
                if ($("nav").css('padding-bottom') == "100px") {
                    $("#header-barre-haute").slideUp();
                    $("#header-container").addClass("fixed-header");
                }
                if ($("#filtres_mobiles").length > 0 && width > 991.98) {
                    $("#recherche-vehicule-scrollbar").css("height", "calc(100vh - 60px");
                }
            }
            else {
                $("#header-barre-haute").slideDown();
                $("#header-container").addClass("fixed-header");

                if ($("#filtres_mobiles").length > 0 && width > 991.98) {
                    $("#recherche-vehicule-scrollbar").css("height", "calc(100vh - 100px");
                }        
            }
            if (scrollPosition >= 100 && scrollPosition <= 75) {
                $('nav').css("padding-bottom", "0");
            }
            else {
                $('nav').css("padding-bottom", "100px");
            }
        } else {
            $("#header-container").addClass("fixed-header");
            $('nav').css("padding-bottom", "60px");
        }
    }
});

$('#header-contact').on('click', function() {
    // $('.contact-hover').slideToggle( function() {
    //     $('#overlay-contact').toggleClass('overlay-contact');
    //     if ($('body').css('overflow') == 'hidden') { $('body').css('overflow', ''); } else { $('body').css('overflow', 'hidden');}
    // });
    $('#modal-header-contact').modal('show');

});

// Gestion de l'autocomplete sur le champ de recherche du header
$("[name='header-search']").on('keyup', function() {
    
    formdata = 'search_string=' + $(this).val();
    formvalues = $(this).val().split(' ');
    $.post(siteURL+"json/vehicules_search", formdata, function(data, status) {
        
        if (data['count'] != 0) { $('.search-autocomplete').show(); $('.search-autocomplete').html(''); } else { $('.search-autocomplete').hide(); }
        search = ''
        for (id in data['data']) {
            // 0 : id
            // 1 : marque
            // 2 : modele
            // 3 : version
            // 4 : millesime
            // 5 : url
            display = data['data'][id][1]+ " " + data['data'][id][2] + " " + data['data'][id][3];
            for (search_term in formvalues) {
                if (formvalues[search_term] != "") {
                    var regEx = new RegExp(formvalues[search_term], "ig");
                    display = display.replace(regEx, '<b>'+formvalues[search_term].toUpperCase()+'</b>');
                }
            }
            search = search + '<div class="search-autocomplete-result cursor-pointer" data-search-url="'+data['data'][id][5]+'" >'+ display+ "</div>";
        }
        $('.search-autocomplete').html(search);

    });
});
// Ouverture d'une nouvelle page lors de l'appui sur un resultat autocomplete
$('body').on('click', '.search-autocomplete-result' , function() {
    window.open($(this).data('searchUrl'),"_self");
});

$('body').on('click', "[data-linkmobile]", function() {
    var linkmobile = $(this).data('linkmobile')
    // console.log($(this).data('linkmobile'));

    $('[data-contenumobile]').each(function(){
        if ($(this).data('contenumobile') == linkmobile) {
            $(this).removeClass('d-none');
            linkparent = $(this).data('contenuparentmobile');
        } else {
            $(this).removeClass('d-none').addClass('d-none');
        }
        // console.log($(this).data('contenumobile'));
    })

    if (linkmobile != "accueil") {
        // Change background color Retour
        $('#header-menu-mobile').removeClass('bg-anthracite').addClass('bg-yellow');
        $('#separation-triangle-mobile').removeClass('triangle-color-anthracite').addClass('triangle-color-other');

        $('#header-menu-mobile').html("<span class='d-flex align-items-center' data-linkmobile='"+linkparent+"'><i class='fas fa-chevron-left'></i>Retour</span>");
        if (linkparent == "accueil") {
            $('#lien-recherche_simplifiee').removeClass('d-none');
            // $('#form-search-mobile').removeClass('col-24').addClass('col-12');
            // $('#searchMenumobile').removeClass('col-18').addClass('col-16');
            // $('#searchMenumobile').attr("placeholder", "Rechercher...");
            // $('#header-menu-search-button-mobile').removeClass('col-4').addClass('col-6');
        }
        
    } else {
        // Change color Logo
        $('#header-menu-mobile').removeClass('bg-yellow').addClass('bg-anthracite');
        $('#separation-triangle-mobile').removeClass('triangle-color-other').addClass('triangle-color-anthracite');
        
        $('#header-menu-mobile').html("<a href='"+siteURL+"'><img src='"+siteURL+"assets/pics/header/logo-original.png' class='img-fluid' /></a>");
        $('#lien-recherche_simplifiee').addClass('d-none');
        // $('#form-search-mobile').removeClass('col-12').addClass('col-24');
        // $('#searchMenumobile').removeClass('col-16').addClass('col-18');
        // $('#searchMenumobile').attr("placeholder", "Rechercher un véhicule");
        // $('#header-menu-search-button-mobile').removeClass('col-6').addClass('col-4');
    }

});

$('#menu_burger, #mobile_menu_background, #menu_burger_close').on('click', function() {
    if (typeof menu_mobile_display === 'undefined' || menu_mobile_display == 0) {
        $('#mobile_menu').css({"transform":"translateX(100%)"});
        $('#mobile_menu_background').css({"transform":"translateX(100%)"});
        $('#menu_burger').removeClass('d-block');
        $('#menu_burger').addClass('d-none');
        $('#menu_burger').toggleClass('fa-bars').toggleClass('fa-times');
        menu_mobile_display = 1;
    }
    else {
        if (window.innerWidth > 575.98 && window.innerWidth < 767.98) {
            $('#mobile_menu').css({"transform":"translateX(-275px)"});
        } else if (window.innerWidth > 767.98 && window.innerWidth < 991.98) {
            $('#mobile_menu').css({"transform":"translateX(-370px)"});
        } else {
            $('#mobile_menu').css({"transform":"translateX(-250px)"});
        }
        $('#mobile_menu_background').css({"transform":"translateX(-100%)"});
        $('#menu_burger').removeClass('d-none');
        $('#menu_burger').addClass('d-block');

        $('#menu_burger').toggleClass('fa-bars').toggleClass('fa-times');
        menu_mobile_display = 0;
    }
});

$('.menu-link').on('click', function() {
    that = this;
    $('.menu-link').each( function () {
        if ($(this).next('.menu-sub').is(':visible') && this != that) {
            //$(that).find('i').toggleClass('fa-chevron-down').toggleClass('fa-chevron-up');
            $(this).next('.menu-sub').slideToggle();
        }
    })
    //$(this).find('i').toggleClass('fa-chevron-down').toggleClass('fa-chevron-up');
    $(this).next('.menu-sub').slideToggle();
});

$(document).ready(function() {
    // Gestion des pagers
    setInterval( function() {
        size_pagers = $('[id^="pager-"]').length - 1;
        if (typeof initial_pager === 'undefined') {initial_pager = 0; next_pager = 0}
        if (next_pager == size_pagers) { next_pager = 0; }
        else { next_pager = initial_pager + 1;}
        // $("#pager-"+initial_pager).fadeOut(400, function() {
        //     $("#pager-"+(next_pager)).fadeIn(400);
        // });
        $('#pager-'+initial_pager).removeClass('hide');
        $('#pager-'+initial_pager).addClass('hiden');
        $('#pager-'+next_pager).removeClass('hiden');
        $('#pager-'+next_pager).addClass('hide');
        initial_pager = next_pager;
    }, 15000);
});
