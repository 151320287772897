$(document).ready(function() {
    //Prise de contact via téléphone 
    $("#submit-telephone").on("click", function() {
        telephone = $("#contact-telephone").val();

        if (telephone.length == 10 && telephone.match("[0-9]")) {
            $.ajax({
                method: "POST",
                url: siteURL+"relance_telephone",
                data: {
                    'telephone': telephone
                }
            }).done(function(data) {
                if (data == "piratage") {
                    $("#piratage_tel").show();
                } else if (data == "envoi") {
                    $("#envoi_tel").show();
                } else {
                    $("#success_tel").show();
                    send_conversion('nAbJCMyT0_cCEIb295ID');

                    window.setTimeout(function() {
                        $("#success_tel").fadeTo(4000, 1).slideUp(500, function() {
                            $(this).hide();
                        });
                    });
                }
            })
        } else {
            $("#fail_tel").show();
            window.setTimeout(function() {
                $("#fail_tel").fadeTo(4000, 1).slideUp(500, function() {
                    $(this).hide();
                });
            });
            return false;
        }
    });
    //Footer, pop-up compte Facebook
    // $("#facebook").on("click", function() {
    //     $("#display-instagram-popup").hide();
    //     window.setTimeout(function() {
    //         $("#display-facebook-popup").fadeTo(300, 1).slideDown(300, function() {
    //             $(this).show();
    //         });
    //     });
    //     $("html, body").animate({ scrollTop: $(document).height() }, 1000);
    // });
    // $("#closerFacebook").on("click", function() {
    //     window.setTimeout(function() {
    //         $("#display-facebook-popup").fadeTo(300, 1).slideUp(300, function() {
    //             $(this).hide();
    //         });
    //     });
    // });

    //Footer, pop-up compte Instagram
    // $("#instagram").on("click", function() {
    //     $("#display-facebook-popup").hide();
    //     window.setTimeout(function() {
    //         $("#display-instagram-popup").fadeTo(300, 1).slideDown(300, function() {
    //             $(this).show();
    //         });
    //     });
    //     $("html, body").animate({ scrollTop: $(document).height() }, 1000);
    // });
    // $("#closerInstagram").on("click", function() {
    //     window.setTimeout(function() {
    //         $("#display-instagram-popup").fadeTo(300, 1).slideUp(300, function() {
    //             $(this).hide();
    //         });
    //     });
    // });

});

$('body').on('change keyup', "#contact-telephone", function(e) {
    $(this).val($(this).val().match(/^\d{0,10}/gi));
    if ($(this).val() != "") {
        $(this).val($(this).val().replace(/,/g, ""));
    }
});
$('body').on('blur', "#contact-telephone", function(e) {
    $(this).val($(this).val().match(regexAntiPiratageTel)[0]);
});
