if ($('[id^="modal-publicite-popup-"]').length > 0) {
	$('[id^="modal-publicite-popup-"]').modal('show');
}

$('[id^="modal-publicite-popup-"]').on('hide.bs.modal', function (e) {
    form = new FormData();
    form.append('pubpopupid', this.id.replace('modal-publicite-popup-', ''));

	document.cookie = "last_pub_shown="+this.id.replace('modal-publicite-popup-', '')+"; path=/";

})